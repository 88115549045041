import * as React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useAppDispatch } from 'redux/store'
import { FormField } from 'sdk/components'
import { showAlertWithDelay } from 'redux/alertReducer'

import { apiRequest, HttpMethod, trackClick } from 'core/utils'

import { Panel } from '../../components/panel/Panel'
import { ProfileForm } from '../../components/profile-form/ProfileForm'

interface Inputs {
  password: string
  password_confirmation: string
  current_password: string
}

const fields: (keyof Inputs)[] = ['password', 'password_confirmation', 'current_password']

export const ChangePasswordSection: React.FC = () => {
  const { register, handleSubmit, reset, setError, formState: { errors, isSubmitting } } = useForm<Inputs>()
  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<Inputs> = (user) => apiRequest<void>(
    '/api/password',
    { user },
    HttpMethod.Patch,
  ).then(() => {
    showAlertWithDelay('Password has been successfully updated', dispatch)
    reset()
  })
    .catch((errors) => {
    // TODO: make this function shared for all forms
      if (errors.errors) {
        fields.forEach((field) => {
          errors.errors[field] && setError(field, { message: errors.errors[field] })
        })
      }
    })

  return (
    <Panel>
      <ProfileForm
        onSubmit={handleSubmit(onSubmit)}
        submitButtonOnClick={() => trackClick('Submit_change_password')}
        isSubmitting={isSubmitting}
      >
        <FormField
          {...register('current_password')}
          label="Password:"
          placeholder="Enter your current password"
          error={errors.current_password?.message}
          type="password"
          autoComplete="off"
        />

        <FormField
          {...register('password')}
          label="New Password:"
          placeholder="Create new password"
          error={errors.password?.message}
          type="password"
          autoComplete="off"
        />

        <FormField
          {...register('password_confirmation')}
          label="Confirm Password:"
          placeholder="Confirm new password"
          error={errors.password_confirmation?.message}
          type="password"
          autoComplete="off"
        />
      </ProfileForm>
    </Panel>
  )
}
