import { ProductItemData } from 'sdk/products-carousel/interfaces'

import { LinksRecord } from 'core/interfaces'

import vslImage from './assets/vsl.jpeg'
import swamImage from './assets/swam.jpeg'
import mntraImage from './assets/mntra.jpeg'

export const buildProducts = (links: LinksRecord): ProductItemData[] => [
  {
    title: 'Vienna Power House',
    description: 'Vienna Power House is a groundbreaking add-on for ' +
      'Vienna MIR Pro 3D that offloads convolution processes to the ' +
      'graphics card.  Stay tuned for more GPU-powered VSL ' +
      'products coming soon.',
    image: vslImage,
    link: links.mir_pro_3d,
  },
  {
    title: 'SWAM String Sections',
    description: 'GPU Audio Technology is coming to Audio Modeling\'s SWAM (Synchronous Waves Acoustic Modeling) - a' +
      ' new-generation proprietary technology that enables musicians, performers, and composers to overcome the ' +
      'classic limitations of traditional samplers.',
    image: swamImage,
    link: links.swam_strings,
  },
  {
    title: 'Mntra Living Sky',
    description: 'Living Sky introduces a innnovative concept by ' +
      'transforming virtual space into a playable instrument, ' +
      'showcasing multiple zones of higher order ambisonics all ' +
      'powered by GPU.',
    image: mntraImage,
    link: links.mntra_living_sky,
    backgroundAlign: 'left',
  },
]
