import * as React from 'react'

import { Hero } from 'pages/use-our-tech-page/sections/hero/Hero'
import { Content } from 'sdk/components'
import { GetOurSDK } from 'sdk/sections'

import { NewEra } from './sections/new-era/NewEra'
import { IndustryLeaders } from './sections/industry-leaders/IndustryLeaders'
import { ExploreAndDive } from './sections/explore-and-dive/ExploreAndDive'

import classes from './style.module.scss'

export const UseOurTechPage: React.FC = () => {
  return (
    <>
      <Hero />
      <Content className={classes.content}>
        <NewEra />
        <IndustryLeaders />
        <ExploreAndDive />
      </Content>
      <GetOurSDK />
    </>
  )
}
