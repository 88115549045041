import * as React from 'react'
import classnames from 'classnames'

import { ActionButtonLogOut } from 'sdk/action-buttons/log-out/ActionButtonLogOut'
import { PopupId, showPopup } from 'redux/popupReducer'
import { useAppDispatch } from 'redux/store'
import { Text, TextName } from 'sdk'

import { LinksRecord, userProfileSection, UserRecord } from 'core/interfaces'
import { trackClick } from 'core/utils'

import { Panel } from '../components/panel/Panel'
import './style.scss'

interface ComponentProps {
  user: UserRecord
  currentSection: userProfileSection
  setCurrentSection: React.Dispatch<React.SetStateAction<userProfileSection>>
  links: LinksRecord
}

export const ProfilePanel: React.FC<ComponentProps> = ({
  user,
  setCurrentSection,
  currentSection,
}) => {
  const dispatch = useAppDispatch()

  return (
    <Panel className="profile-panel">
      <div className="profile-panel__user-block">
        <div className="profile-panel__username-container">
          <h2 className="profile-panel__username">
            { user.name ? user.name : 'Hi, new user!' }
          </h2>

          <Text type={TextName.Subtext} className="profile-panel__member-since--mobile">
            User since { user.user_since }
          </Text>

          <ActionButtonLogOut
            className="profile-panel__sign-out-button"
            onClick={() => dispatch(showPopup(PopupId.SignOut))}
          />
        </div>
        <Text type={TextName.Subtext} className="profile-panel__member-since">
          User since { user.user_since }
        </Text>
        <div className="profile-panel__section-selectors">
          <button
            className={classnames(
              'profile-panel__section-selector',
              'profile-panel__section-selector-first',
              { 'profile-panel__section-selector--active': currentSection === 'myProducts' },
            )}
            onClick={() => {
              trackClick('My_products')
              setCurrentSection('myProducts')
            }}
          >
            My products
          </button>
          <button
            className={classnames(
              'profile-panel__section-selector',
              'profile-panel__section-selector-second',
              { 'profile-panel__section-selector--active': currentSection === 'accountDetails' },
            )}
            onClick={() => {
              trackClick('Account_details')
              setCurrentSection('accountDetails')
            }}
          >
            Account details
          </button>
          <button
            className={classnames(
              'profile-panel__section-selector',
              'profile-panel__section-selector-third',
              { 'profile-panel__section-selector--active': currentSection === 'changePassword' },
            )}
            disabled={user.provider !== null}
            onClick={() => {
              if (user.provider === null) {
                trackClick('Change_password')
                setCurrentSection('changePassword')
              }
            }}
          >
            Change password
          </button>
        </div>
      </div>
    </Panel>
  )
}
