import React, { FC } from 'react'

import { SignInForm } from './form/SignInForm'

import { AuthorizationPopup } from '../authorization-popup/AuthorizationPopup'

export const SignIn: FC = () => {
  return (
    <AuthorizationPopup>
      <SignInForm />
    </AuthorizationPopup>
  )
}
