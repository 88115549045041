import React, { FC } from 'react'

import { SignUpForm } from './form/SignUpForm'

import { AuthorizationPopup } from '../authorization-popup/AuthorizationPopup'

export const SignUp: FC = () => {
  return (
    <AuthorizationPopup isLogIn={false}>
      <SignUpForm />
    </AuthorizationPopup>
  )
}
