import * as React from 'react'

import { Button } from 'sdk/button/Button'

import { UserRecord } from 'core/interfaces'

import iconUnhovered from './assets/account.svg'
import iconHovered from './assets/account-hovered.svg'

interface ComponentProps {
  user: UserRecord | undefined
  onClick?: () => void
}

export const AccountButton: React.FC<ComponentProps> = (props) => {
  const { user, onClick } = props

  return (
    <Button
      resizable={true}
      iconHovered={iconHovered}
      iconUnhovered={iconUnhovered}
      onClick={onClick}
    >
      { user?.name ? `Hi, ${user.name}` : 'My Account' }
    </Button>
  )
}