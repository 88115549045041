import * as React from 'react'

import { useAppSelector } from 'redux/store'
import { Content } from 'sdk/components'

import { buildProducts } from './data'

import { Text, TextName } from '../../text/Text'
import { ProductsCarousel } from '../../products-carousel/ProductsCarousel'

import classes from './style.module.scss'

export const PoweredProducts: React.FC = () => {
  const links = useAppSelector((state) => state.settings.links)
  const products = React.useMemo(() => {
    return buildProducts(links!)
  }, [])

  return (
    <Content className={classes.container}>
      <Text
        type={TextName.H2}
        className={classes.heading}
      >
        Explore Products Powered by GPU Audio
      </Text>

      <ProductsCarousel products={products} />
    </Content>
  )
}
