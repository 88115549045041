import * as React from 'react'

import { Hero } from './sections/hero/Hero'

export const SupportPage: React.FC = () => {
  return (
    <>
      <Hero />
    </>
  )
}
