import * as React from 'react'
import classNames from 'classnames'

import { ActionButtonDiscover } from 'sdk/action-buttons/ActionButtonDiscover'
import { Button } from 'sdk/button/Button'

import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  title: string
  description: string
  image: string
  isActive: boolean
  link?: string
  backgroundAlign?: 'left' | 'center' | 'right'
}

export const ProductItem: React.FC<ComponentProps> = (props) => {
  const {
    title,
    description,
    image,
    isActive,
    link,
    backgroundAlign,
  } = props

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        backgroundPositionX: backgroundAlign,
      }}
      className={classNames(
        classes.container,
        { [classes.isActive]: isActive },
      )}
    >
      <div className={classes.content}>
        <Text
          type={TextName.H3}
          className={classes.title}
        >
          { title }
        </Text>
        <Text
          type={TextName.Text}
          className={classes.description}
        >
          { description }
        </Text>
        { link ? (
          <ActionButtonDiscover href={link} />
        ) : (
          // TODO: implement subscription in JSW-583
          <Button disabled>
            Get updates
          </Button>
        ) }
      </div>
    </div>
  )
}

ProductItem.defaultProps = {
  backgroundAlign: 'center',
}
