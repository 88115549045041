import { useNavigate } from 'react-router-dom'

export type RichText = string

export interface UserRecord {
  name: string
  surname: string
  email: string
  user_since: string
  provider: string
  uid: string
  newsfeed_subscribe: boolean
}

interface BackgroundImage {
  url: string
  collage: { url: string }
}

export interface WithUriAlias {
  pathname: string
}

export interface WithBackgroundImage {
  bg_image: BackgroundImage
}

export interface JobRecord extends WithUriAlias {
  id: number
  title: string
}

export interface JobFullRecord extends JobRecord {
  content: RichText
}

export enum NewsfeedRecordType {
  Article = 'Newsfeed::Article',
  Event = 'Newsfeed::Event',
}

interface NewsfeedBaseRecord extends WithUriAlias, WithBackgroundImage {
  id: number
  title: string
  preview: string
  type: NewsfeedRecordType
  views: number
}

export interface ArticleRecord extends NewsfeedBaseRecord {
  human_date: string
  read_time: string
  type: NewsfeedRecordType.Article
}

export interface EventRecord extends NewsfeedBaseRecord {
  event_type: string
  registration_link: string
  human_date: string
  human_time: string
  type: NewsfeedRecordType.Event
}

export type NewsfeedEntry = EventRecord | ArticleRecord

export interface ArticleFullRecord extends ArticleRecord {
  content: RichText
  related_items: NewsfeedEntry[]
}

export interface EventFullRecord extends EventRecord {
  content: RichText
  related_items: NewsfeedEntry[]
}

export type NewsfeedFullEntry = EventFullRecord | ArticleFullRecord

export interface ProductVersionRecord {
  human_release_date: string
  id: number
  name: string
  version: string
}

export interface ProductRecord {
  id: number
  name: string
  badge: string
  readme_link: string
  description: RichText
  published_versions: ProductVersionRecord[]
}

export interface LinksRecord {
  discord: string
  google_path: string
  facebook_path: string
  mir_pro_3d: string
  swam_strings: string
  mntra_living_sky: string
  contact_email: string
  sdk_form: string
}

export interface FlashProps {
  notice?: string
}

export interface EntryPointProps {
  user: UserRecord
  flash: FlashProps
  links: LinksRecord
  debug: boolean
  mobile: boolean,
  has_jobs: boolean,
  preloaded_data: PreloadedData,
}

export const enum SectionId {
  FlagshipProduct = 'flagship-product',
}

export type userProfileSection = 'myProducts' | 'accountDetails' | 'changePassword'

export type History = ReturnType<typeof useNavigate>

export interface StaticPageRecord {
  content: RichText
}

export enum PreloadedDataType {
  Item = 'item',
  Index = 'index',
}

export enum PreloadedRecordType {
  Jobs = 'jobs',
  Newsfeed = 'newsfeed',
  StaticPage = 'static_page'
}

export interface PreloadedData {
  record: PreloadedRecordType
  type: PreloadedDataType
  data: unknown,
}

export type PreloadedDataRequestId = Pick<PreloadedData, 'record' | 'type'>
