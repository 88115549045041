import * as React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useAppDispatch } from 'redux/store'
import { loginUser } from 'redux/userReducer'
import { showAlertWithDelay } from 'redux/alertReducer'
import { FormField } from 'sdk/components'

import { UserRecord } from 'core/interfaces'
import { apiRequest, HttpMethod, trackClick } from 'core/utils'

import { Panel } from '../../components/panel/Panel'
import { ProfileForm } from '../../components/profile-form/ProfileForm'

import classes from './style.module.scss'

interface Inputs {
  name: string
  surname: string
  email: string
}

const fields: (keyof Inputs)[] = ['name', 'surname', 'email']

export const AccountDetailsSection: React.FC<{ user: UserRecord }> = ({ user }) => {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, reset, setValue, setError, formState: { errors, isSubmitting } } = useForm<Inputs>({
    defaultValues: { name: user.name, surname: user.surname, email: user.email },
  })

  const onSubmit: SubmitHandler<Inputs> = (user) => apiRequest<UserRecord>(
    '/api/profile',
    { user },
    HttpMethod.Patch,
  )
    .then((userRecord) => {
      reset()
      showAlertWithDelay('Profile has been successfully updated', dispatch)
      dispatch(loginUser(userRecord))
      fields.forEach((field) => {
        setValue(field, userRecord[field])
      })
    })
    .catch((errors) => {
      if (errors.errors) {
        fields.forEach((field) => {
          errors.errors[field] && setError(field, { message: errors.errors[field] })
        })
      }
    })

  return (
    <Panel>
      <ProfileForm
        onSubmit={handleSubmit(onSubmit)}
        submitButtonOnClick={() => trackClick('Submit_account_details')}
        isSubmitting={isSubmitting}
      >
        <div className={classes.fullNameBlock}>
          <FormField
            {...register('name')}
            label="Name:"
            placeholder="Please enter your name"
            type="text"
            autoComplete="off"
            error={errors.name?.message}
          />

          <FormField
            {...register('surname')}
            label="Surname:"
            placeholder="Please enter your surname"
            type="text"
            autoComplete="off"
            error={errors.surname?.message}
          />
        </div>

        <FormField
          {...register('email')}
          disabled
          label="E-mail:"
          placeholder="Please enter your e-mail"
          type="email"
          autoComplete="off"
          error={errors.email?.message}
        />
      </ProfileForm>
    </Panel>
  )
}
