import * as React from 'react'

import { SimpleTitleButtonHero } from 'sdk/sections'
import { ActionButtonTuneIn } from 'sdk/action-buttons/ActionButtonTuneIn'

import heroImage from './assets/support-hero-image.jpeg'

import classes from './style.module.scss'

export const Hero: React.FC = () => (
  <SimpleTitleButtonHero
    title="Join GPU Audio Community"
    description="You can get feedback, chat with other creators and collaborate in our Discord channel"
    actionButton={(
      <ActionButtonTuneIn className={classes.button}/>
    )}
    imageLink={heroImage}
  />
)
