import * as React from 'react'

import { Button } from 'sdk/button/Button'
import { useAppSelector } from 'redux/store'

import icon from './assets/sdk-hovered.svg'

export const ActionButtonGetSDK: React.FC = () => {
  const sdkLink = useAppSelector((state) => state.settings.links?.sdk_form)

  return (
    <Button
      iconHovered={icon}
      as="link"
      href={sdkLink}
      target="_blank"
    >
      Get our SDK
    </Button>
  )
}
