import * as React from 'react'

import { MenuItemFooter } from 'sdk/menu-items/menu-item-footer/MenuItemFooter'
import { BackToTopButton } from 'sdk/back-to-top-button/BackToTopButton'
import { SocialNetworks } from 'sdk/social-networks/SocialNetworks'
import { Content } from 'sdk/components'
import { useAppSelector } from 'redux/store'

import { LinksRecord } from 'core/interfaces'

import logo from 'images/logo.png'
import logoRetina from 'images/logo@2x.png'

import classes from './style.module.scss'

interface MenuSection {
  label: string
  items: MenuItem[]
}

interface MenuItem {
  label: string
  link: string
  hidden?: boolean
  external?: boolean
}
interface ComponentProps {
  showJobs: boolean
}

const buildMenuItems = (showJobs: boolean, links: LinksRecord): MenuSection[] => ([
  {
    label: 'Products',
    items: [
      { label: 'Vienna Power House', link: links.mir_pro_3d, external: true },
      { label: 'SWAM Strings', link: links.swam_strings, external: true },
      { label: 'Mntra Living Sky', link: links.mntra_living_sky, external: true },
    ],
  },
  {
    label: 'Business',
    items: [
      { label: 'Use Our Tech', link: 'use-our-tech' },
      { label: 'Jobs', link: 'jobs', hidden: !showJobs },
    ],
  },
  {
    label: 'Community',
    items: [
      { label: 'News & Events', link: 'newsfeed' },
      { label: 'Discord', link: links.discord, external: true },
    ],
  },
  {
    label: 'Legal',
    items: [
      { label: 'Privacy Policy', link: 'privacy-policy' },
      { label: 'Terms of Use', link: 'terms-of-use' },
      { label: 'Cookie Policy', link: 'cookie-policy' },
    ],
  },
])

export const Footer: React.FC<ComponentProps> = ({ showJobs }) => {
  const links = useAppSelector((state) => state.settings.links)
  const menuItems = buildMenuItems(showJobs, links!)

  return (
    <Content
      mainWrapperClassName={classes.wrapper}
      className={classes.container}
    >
      <BackToTopButton className={classes.backToTopButton} />
      <div className={classes.headerContainer}>
        <div className={classes.logo}>
          <img
            src={logo}
            srcSet={`${logo} 1x, ${logoRetina} 2x`}
            alt="GPU Audio logo"
          />
        </div>
        <SocialNetworks />
      </div>

      <div className={classes.menuContainer}>
        { menuItems.map((menuColumn, colIndex) => (
          <div key={colIndex}>
            <div className={classes.columnTitle}>
              { menuColumn.label }
            </div>
            <ul className={classes.columnList}>
              { menuColumn.items.filter(({ hidden }) => !hidden).map((item, itemIndex) => (
                <li key={itemIndex}>
                  <MenuItemFooter link={item.link} external={!!item.external}>{ item.label }</MenuItemFooter>
                </li>
              )) }
            </ul>
          </div>
        )) }
      </div>

      <hr />

      <div className={classes.footerContainer}>
        <div className={classes.legal}>
          © 2017-2024, GPU Audio, Inc. All rights reserved.
        </div>
      </div>
    </Content>
  )
}
