import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/learn-hovered.svg'

interface ComponentProps {
  onClick: () => void
}

export const ActionButtonLearnMore: React.FC<ComponentProps> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      iconHovered={icon}
    >
      Learn more
    </Button>
  )
}
