import React, { FC, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'

import { useAppSelector } from 'redux/store'
import { FormPopup } from 'sdk/components'
import { ButtonTo, Text, TextName } from 'sdk'

import facebookIcon from './assets/facebook-colorful.svg'
import googleIcon from './assets/google-colorful.svg'

import classes from './style.module.scss'

interface ComponentProps {
  isLogIn?: boolean
}

export const AuthorizationPopup: FC<PropsWithChildren<ComponentProps>> = (props) => {
  const { children, isLogIn } = props
  const links = useAppSelector((state) => state.settings.links)
  const navigate = useNavigate()

  return (
    <FormPopup title="Authorization" onClose={() => navigate(-1)}>
      <div className={classes.authTypeButtons}>
        <button
          onClick={() => navigate('/sign-in', { replace: true })}
          className={classnames(
            classes.authTypeButton,
            { [classes.isActive]: isLogIn },
          )}
        >
          <Text type={TextName.Text}>
            Log in
          </Text>
        </button>

        <div className={classes.verticalDivider} />

        <button
          onClick={() => navigate('/sign-up', { replace: true })}
          className={classnames(
            classes.authTypeButton,
            { [classes.isActive]: !isLogIn },
          )}
        >
          <Text type={TextName.Text}>
            Sign up
          </Text>
        </button>
      </div>

      <div className={classes.oauthButtons}>
        <ButtonTo
          className={classes.oauthButton}
          href={links!.facebook_path}
        >
          <img
            width={40}
            height={40}
            src={facebookIcon}
            alt="facebook icon"
          />
          <Text type={TextName.Subtext}>
            Facebook
          </Text>
        </ButtonTo>

        <ButtonTo
          className={classes.oauthButton}
          href={links!.google_path}
        >
          <img
            width={40}
            height={40}
            src={googleIcon}
            alt="google icon"
          />
          <Text type={TextName.Subtext}>
            Google
          </Text>
        </ButtonTo>
      </div>

      <div className={classes.orBlock}>
        <Text
          type={TextName.Subtext}
        >
          Or use your credentials
        </Text>
        <div className={classes.horizontalDivider} />
      </div>

      { children }
    </FormPopup>
  )
}

AuthorizationPopup.defaultProps = {
  isLogIn: true,
}
