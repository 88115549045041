import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormPopup } from 'sdk/components'

import { SubmitEmailForm } from './form/SubmitEmailForm'

interface ComponentProps {
  passwordRecovery?: boolean
}

export const SubmitEmail: FC<ComponentProps> = ({ passwordRecovery }) => {
  const navigate = useNavigate()

  return (
    <FormPopup
      title={passwordRecovery ? 'Password Recovery': 'Resend Confirmation Instructions'}
      onClose={() => navigate(-1)}
    >
      <SubmitEmailForm
        apiUrl={passwordRecovery ? '/api/users/password' : '/api/users/confirmation'}
      />
    </FormPopup>
  )
}

SubmitEmail.defaultProps = {
  passwordRecovery: true,
}
