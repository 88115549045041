import React, { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'redux/store'
import { loginUser } from 'redux/userReducer'
import { closePopup } from 'redux/popupReducer'
import { PopupForm, FormField, FormFieldSize } from 'sdk/components'
import { ActionButtonEnter } from 'sdk/action-buttons/ActionButtonEnter'

import { apiRequest, HttpMethod, trackClick, trackUid } from 'core/utils'
import { UserRecord } from 'core/interfaces'

interface Inputs {
  password: string
  password_confirmation: string
}

export const ChangePasswordForm: FC = () => {
  const { register, handleSubmit, reset, setError, formState: { errors, isSubmitting } } = useForm<Inputs>()
  const navigate = useNavigate()
  const [formError, setFormError] = useState<string>('')
  const dispatch = useAppDispatch()
  const tokenMatch = window.location.search.match('token=(.+)')
  const token = tokenMatch ? tokenMatch[1] : ''

  const onSubmit: SubmitHandler<Inputs> = (user) => apiRequest<UserRecord>('/api/users/password.json', {
    user: {
      ...user,
      reset_password_token: token,
    },
  }, HttpMethod.Patch)
    .then((userRecord) => {
      dispatch(loginUser(userRecord))
      trackUid(userRecord)
      reset()
      dispatch(closePopup())
      navigate('/user-profile')
    })
    .catch((response) => {
      const { errors } = response
      errors?.password && setError('password', { message: errors.password })
      errors?.password_confirmation && setError('password_confirmation', { message: errors.password_confirmation })
      errors?.reset_password_token && setFormError('Reset password link is invalid or already used')
    })

  return (
    <PopupForm
      baseError={formError}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormField
        {...register('password')}
        fieldSize={FormFieldSize.small}
        label="New password: *"
        type="password"
        error={errors.password?.message}
      />

      <FormField
        {...register('password_confirmation')}
        fieldSize={FormFieldSize.small}
        label="Confirm new password: *"
        type="password"
        error={errors.password_confirmation?.message}
      />

      <ActionButtonEnter
        onClick={() => trackClick('Submit_new_password')}
        disabled={isSubmitting}
      >
        Set new password and login
      </ActionButtonEnter>
    </PopupForm>
  )
}
