import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormPopup } from 'sdk/components'

import { ChangePasswordForm } from './form/ChangePasswordForm'

export const ChangePassword: FC = () => {
  const navigate = useNavigate()

  return (
    <FormPopup title="Password Recovery" onClose={() => navigate(-1)}>
      <ChangePasswordForm />
    </FormPopup>
  )
}
