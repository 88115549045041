import * as React from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'

import graphicsCardIcon from './assets/graphics-card.png'
import graphicsCardIconRetina from './assets/graphics-card@2x.png'
import gaugeIcon from './assets/gauge.png'
import gaugeIconRetina from './assets/gauge@2x.png'
import circuitIcon from './assets/circuit.png'
import circuitIconRetina from './assets/circuit@2x.png'
import lightningIcon from './assets/lightning.png'
import lightningIconRetina from './assets/lightning@2x.png'
import scaleIcon from './assets/scale.png'
import scaleIconRetina from './assets/scale@2x.png'

import classes from './style.module.scss'

export const features = [
  {
    icon: graphicsCardIcon,
    iconRetina: graphicsCardIconRetina,
    alt: 'graphic card',
    textType: TextName.H3,
    description: 'The world\'s first novel technology that enables real-time audio DSP on GPUs',
    className: classes.div1,
  },
  {
    icon: gaugeIcon,
    iconRetina: gaugeIconRetina,
    alt: 'gauge icon',
    textType: TextName.Text,
    description: 'Real-time high performance DSP',
    className: classes.div2,
  },
  {
    icon: circuitIcon,
    iconRetina: circuitIconRetina,
    alt: 'circuit icon',
    textType: TextName.Text,
    description: 'Powerful parallel processing, without added latency',
    className: classes.div3,
  },
  {
    icon: lightningIcon,
    iconRetina: lightningIconRetina,
    alt: 'lightning icon',
    textType: TextName.Text,
    description: 'Achieve 1ms latency, regardless of channels or effects',
    className: classes.div4,
  },
  {
    icon: scaleIcon,
    iconRetina: scaleIconRetina,
    alt: 'scale icon',
    textType: TextName.Text,
    description: 'Networkability and scalability',
    className: classes.div5,
  },
]

interface ComponentProps {
  className: string
  icon: string
  iconRetina: string
  alt: string
  textType: TextName
  description: string
}

const FeatureItem: React.FC<ComponentProps> = ({
  className,
  icon,
  iconRetina,
  alt,
  textType,
  description,
}) => (
  <div className={classNames(classes.item, className)}>
    <img
      src={icon}
      srcSet={`${icon} 1x, ${iconRetina} 2x`}
      alt={alt}
      className={classes.itemIcon}
    />
    <Text type={textType}>
      { description }
    </Text>
  </div>
)

export const NewEra: React.FC = () => {
  return (
    <div className={classes.container}>
      <Text type={TextName.H2} className={classes.title}>
        The New Era of Audio is GPU Powered
      </Text>

      <div className={classes.features}>
        { features.map((feature, index) => (
          <FeatureItem
            key={index}
            icon={feature.icon}
            iconRetina={feature.iconRetina}
            alt={feature.alt}
            textType={feature.textType}
            description={feature.description}
            className={feature.className}
          />
        )) }
      </div>
    </div>
  )
}
