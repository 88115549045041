import * as React from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'
import { SocialNetworks } from 'sdk/social-networks/SocialNetworks'
import { MenuItemHeader } from 'sdk/menu-items/menu-item-header/MenuItemHeader'

import './style.scss'

interface ComponentProps {
  menuOpened: boolean
  closeMenu(): void
}

export const MobileMenu: React.FC<ComponentProps> = (props) => {
  const {
    menuOpened,
    closeMenu,
  } = props

  return (
    <div className={classNames('mobile-menu', { 'mobile-menu--active': menuOpened })}>
      <div className="mobile-menu__links">
        <MenuItemHeader link="/" onClick={closeMenu}>
          Home
        </MenuItemHeader>
        <MenuItemHeader link="/use-our-tech" onClick={closeMenu}>
          Use Our Tech
        </MenuItemHeader>
        <MenuItemHeader link="/newsfeed" onClick={closeMenu}>
          News & Events
        </MenuItemHeader>
        <MenuItemHeader link="/support" onClick={closeMenu}>
          Support
        </MenuItemHeader>
      </div>

      <div className="mobile-menu__bottom">
        <SocialNetworks />
        <hr />
        <Text type={TextName.Subtext}>
          © 2017-2024, GPU Audio, Inc. All rights reserved.
        </Text>
      </div>
    </div>
  )
}
