import React, { FormEventHandler } from 'react'

import { getCsrfToken } from 'core/utils'

import classes from './style.module.scss'

interface ComponentProps {
  href: string,
  children: React.ReactNode,
  className?: string
}

const onSubmit: FormEventHandler<HTMLFormElement> = (event: React.FormEvent<HTMLFormElement>): void => {
  event.currentTarget.children[1].setAttribute('value', getCsrfToken())
}

/**
 * React Implementation of RoR button_to method.
 */
export const ButtonTo: React.FC<ComponentProps> = ({ href, children, className }) => {
  return (
    <form onSubmit={onSubmit} className={className} method="post" action={href}>
      <button
        className={classes.button}
        type="submit"
      >
        { children }
      </button>
      <input type="hidden" name="authenticity_token" value={getCsrfToken()}></input>
    </form>
  )
}
