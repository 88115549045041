import React, { FC, FormEventHandler, PropsWithChildren } from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/save-hovered.svg'

import classes from './style.module.scss'

interface ComponentProps {
  isSubmitting?: boolean
  onSubmit?: FormEventHandler
  submitButtonOnClick?: () => void
  className?: string
}

export const ProfileForm: FC<PropsWithChildren<ComponentProps>> = (props) => {
  const {
    onSubmit,
    children,
    submitButtonOnClick,
    isSubmitting,
  } = props

  return (
    <form
      onSubmit={onSubmit}
      className={classes.form}
    >
      { children }

      <Button
        disabled={isSubmitting}
        iconHovered={icon}
        className={classes.button}
        onClick={submitButtonOnClick}
      >
        Save
      </Button>
    </form>
  )
}
