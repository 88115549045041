import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Sentry from '@sentry/react'

import { loginUser } from 'redux/userReducer'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { TrackLink } from 'components/track-link/TrackLink'
import { closePopup, PopupId, showPopup } from 'redux/popupReducer'
import { PopupForm, Checkbox, FormField, FormFieldSize } from 'sdk/components'
import { ActionButtonEnter } from 'sdk/action-buttons/ActionButtonEnter'

import { UserRecord } from 'core/interfaces'
import {
  apiRequest,
  processBENotification,
  trackClick,
  trackEvent,
  trackUid,
} from 'core/utils'

import classes from './style.module.scss'

interface Inputs {
  name: string
  email: string
  password: string
  terms_of_use_accepted: boolean
  newsfeed_subscribe: boolean
}

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>()
  const dispatch = useAppDispatch()
  const { mobile } = useAppSelector((state) => state.settings)

  const [formError, setFormError] = React.useState<string>('')

  const onSubmit: SubmitHandler<Inputs> = async (user) => apiRequest<UserRecord>('/api/users', { user })
    .then((userRecord) => {
      dispatch(loginUser(userRecord))
      trackUid(userRecord)
      reset()
      trackEvent('Registration', 'Success')
      processBENotification(dispatch)
      Sentry.setUser({ id: userRecord?.uid, email: userRecord?.email })
      dispatch(closePopup())

      if (mobile) {
        dispatch(showPopup(PopupId.NotificationNewUser))
      } else {
        navigate('/user-profile')
      }
    })
    .catch((errors) => {
      trackEvent('Registration', 'Error')
      errors.error && setFormError(errors.error)
      errors.errors?.name && setError('name', { message: errors.errors.name })
      errors.errors?.email && setError('email', { message: errors.errors.email })
      errors.errors?.password && setError('password', { message: errors.errors.password })
    })

  const acceptTermsOfService = watch('terms_of_use_accepted', false)

  return (
    <PopupForm
      onSubmit={handleSubmit(onSubmit)}
      baseError={formError}
    >
      <div className={classes.row}>
        <FormField
          {...register('name')}
          fieldSize={FormFieldSize.small}
          label="Name:"
          placeholder="Enter your name"
          type="text"
          error={errors.name?.message}
        />

        <FormField
          {...register('email')}
          fieldSize={FormFieldSize.small}
          autoComplete="off"
          label="E-mail: *"
          placeholder="Enter your e-mail"
          type="email"
          error={errors.email?.message}
        />
      </div>

      <FormField
        {...register('password')}
        fieldSize={FormFieldSize.small}
        autoComplete="new-password"
        label="Password: *"
        placeholder="Enter your password"
        type="password"
        error={errors.password?.message}
      />

      <div className={classes.checkboxes}>
        <Checkbox
          {...register('terms_of_use_accepted')}
          className={classes.checkbox}
          label={
            <>
              I agree to the
              { ' ' }
              <TrackLink
                trackName="Terms_of_use"
                to="/terms-of-use"
                onClick={() => dispatch(closePopup())}
              >
                terms of use
              </TrackLink>
              { ' ' }
              of the site and give consent to the processing of
              { ' ' }
              <TrackLink
                trackName="Privacy_policy"
                to="/privacy-policy"
                onClick={() => dispatch(closePopup())}
              >
                personal data
              </TrackLink>
              { ' ' }
              (required)
            </>
          }
        />

        <Checkbox
          {...register('newsfeed_subscribe')}
          className={classes.checkbox}
          label="I agree to receive e-mail updates and promotions from GPU Audio"
        />
      </div>

      <ActionButtonEnter
        onClick={() => trackClick('Submit_registration')}
        disabled={!acceptTermsOfService || isSubmitting}
      >
        Sign up
      </ActionButtonEnter>
    </PopupForm>
  )
}

SignUpForm.defaultProps = {
  irCompetition: false,
}
