import * as React from 'react'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import { loginUser } from 'redux/userReducer'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { PopupId, showPopup } from 'redux/popupReducer'
import { FormField, FormFieldSize, PopupForm } from 'sdk/components'
import { ActionButtonEnter } from 'sdk/action-buttons/ActionButtonEnter'
import { Text, TextName } from 'sdk'

import { UserRecord } from 'core/interfaces'
import { apiRequest, processBENotification, trackClick, trackEvent, trackUid } from 'core/utils'

import classes from './style.module.scss'

interface Inputs {
  email: string
  password: string
}

export const SignInForm: React.FC = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>()
  const dispatch = useAppDispatch()
  const { mobile } = useAppSelector((state) => state.settings)

  const [formError, setFormError] = React.useState<string>('')

  const onSubmit: SubmitHandler<Inputs> = (user) => apiRequest<UserRecord>('/api/users/sign_in', { user })
    .then((userRecord) => {
      dispatch(loginUser(userRecord))
      trackUid(userRecord)
      trackEvent('Login', 'Success')
      reset()
      processBENotification(dispatch)
      Sentry.setUser({ id: userRecord?.uid, email: userRecord?.email })

      if (mobile) {
        dispatch(showPopup(PopupId.NotificationOldUser))
      } else {
        navigate('/user-profile')
      }
    })
    .catch((errors) => {
      trackEvent('Login', 'Error')
      errors.error && setFormError(errors.error)
      errors.errors?.email && setError('email', { message: errors.errors.email })
      errors.errors?.password && setError('password', { message: errors.errors.password })
    })

  return (
    <PopupForm
      baseError={formError}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormField
        {...register('email')}
        fieldSize={FormFieldSize.small}
        label="E-mail: *"
        placeholder="Enter your e-mail"
        autoComplete="on"
        type="email"
        error={errors.email?.message}
      />

      <FormField
        {...register('password')}
        fieldSize={FormFieldSize.small}
        label="Password: *"
        placeholder="Enter your password"
        autoComplete="on"
        type="password"
        error={errors.password?.message}
      />

      <div className={classes.buttons}>
        <button
          onClick={() => {
            trackClick('Password_recovery')
            navigate('/recovery', { replace: true })
          }}
          type="button"
          className={classes.recoveryButton}
        >
          <Text type={TextName.Subtext}>
            Password recovery
          </Text>
        </button>

        <button
          onClick={() => {
            trackClick('Resend_confirmation')
            navigate('/resend-confirmation', { replace: true })
          }}
          type="button"
          className={classes.recoveryButton}
        >
          <Text type={TextName.Subtext}>
            Resend confirmation email
          </Text>
        </button>
      </div>

      <ActionButtonEnter
        onClick={() => trackClick('Submit_log_in')}
        disabled={isSubmitting}
      >
        Log in
      </ActionButtonEnter>
    </PopupForm>
  )
}
