import * as React from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'

import classes from './style.module.scss'

interface YoutubeEmbedProps {
  embedId: string
  className?: string
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedId, className }) => (
  <div className={className}>
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
)

export const ExploreAndDive: React.FC = () => {
  return (
    <div className={classes.container}>
      <Text type={TextName.H2} className={classes.title}>
        Explore Success Stories and Dive into GPU Audio Tech
      </Text>

      <div className={classes.grid}>
        <YoutubeEmbed
          embedId="-JmM70RkRLY"
          className={classNames(classes.video, classes.videoMain)}
        />

        <YoutubeEmbed
          embedId="ddvxaJV-rTE"
          className={classes.video}
        />
        <YoutubeEmbed
          embedId="jv_xJRurV64"
          className={classes.video}
        />

        <YoutubeEmbed
          embedId="fyfhr_T1FvQ"
          className={classes.video}
        />
        <YoutubeEmbed
          embedId="UmYnoFo0Bb8"
          className={classes.video}
        />
      </div>
    </div>
  )
}
