import React, { FC, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/store'
import { Checkbox } from 'sdk/components'
import { showAlertWithDelay } from 'redux/alertReducer'
import { loginUser } from 'redux/userReducer'
import { Text, TextName } from 'sdk'

import { apiRequest, HttpMethod } from 'core/utils'
import { UserRecord } from 'core/interfaces'

import { Panel } from '../components/panel/Panel'

interface ComponentProps {
  className?: string
}

export const SubscribePanel: FC<ComponentProps> = ({ className }) => {
  const { user: currentUser } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const [isUpdating, setIsUpdating] = useState(false)
  const handleSubscriptionToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked: isChecked } = event.target

    setIsUpdating(true)

    apiRequest<UserRecord>(
      '/api/profile',
      { user: { newsfeed_subscribe: isChecked } },
      HttpMethod.Patch,
    )
      .then((userRecord: UserRecord) => {
        showAlertWithDelay(
          `You have successfully ${isChecked ? 'subscribed' : 'unsubscribed'}`,
          dispatch,
        )
        dispatch(loginUser(userRecord))
      })
      .catch((errors) => console.error(errors))
      .finally(() => setIsUpdating(false))
  }

  return (
    <Panel className={className}>
      <Text
        type={TextName.H3}
        style={{ marginBottom: '1em' }}
      >
        E-mail subscriptions
      </Text>
      <Checkbox
        name="subscribe for e-mail updates"
        label="I agree to receive e-mail updates and promotions from GPU Audio,
            including exclusive offers, discounts, and free giveaways"
        checked={currentUser?.newsfeed_subscribe}
        onChange={handleSubscriptionToggle}
        disabled={isUpdating}
      />
    </Panel>
  )
}
