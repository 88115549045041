import * as React from 'react'

import { GetOurSDK, PoweredProducts } from 'sdk/sections'

import { Hero } from './sections/hero/Hero'
import { FlagshipProduct } from './sections/flagship-product/FlagshipProduct'

export const HomePage: React.FC = () => {
  return (
    <>
      <Hero />
      <FlagshipProduct />
      <PoweredProducts />
      <GetOurSDK />
    </>
  )
}
