import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/tune-in-hovered.svg'

interface ComponentProps {
  className?: string
}

export const ActionButtonTuneIn: React.FC<ComponentProps> = ({ className }) => {
  return (
    <a
      href="https://discord.gg/sJ7zxgeb7T"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        iconHovered={icon}
        className={className}
      >
        Tune in
      </Button>
    </a>
  )
}
