import * as React from 'react'
import classNames from 'classnames'

import { UnsafeHTML } from 'components/unsafe-html/UnsafeHTML'
import { TrackALink } from 'components/track-link/TrackALink'
import { Text, TextName } from 'sdk'
import { Panel } from 'pages/user-profile/components/panel/Panel'
import { ProductsList } from 'pages/user-profile/components/products-list/ProductsList'

import { ProductRecord } from 'core/interfaces'

import classes from './style.module.scss'

interface ComponentProps {
  products: ProductRecord[]
}

export const MyProductsSection: React.FC<ComponentProps> = ({ products }) => {
  return products.map((product) => (
    <Panel
      key={product.id}
      className={classNames(
        classes.panel,
        { [classes.withBadge]: product.badge },
      )}
    >
      <Text type={TextName.H3} className={classes.name}>
        { product.name }
      </Text>

      { product.badge && (
        <div className={classes.badge}>
          { product.badge }
        </div>
      ) }

      <ProductsList versions={product.published_versions} />

      { product.description ? (
        <UnsafeHTML className={classes.description}>
          { product.description }
        </UnsafeHTML>
      ) : null }

      { product.readme_link ? (
        <TrackALink
          className={classes.readmeLink}
          trackName="Product_readme"
          href={product.readme_link}
          target="_blank"
          rel="noreferrer"
        >
          Download the README here
        </TrackALink>
      ) : null }
    </Panel>
  ))
}
