import * as React from 'react'

import { SimpleTitleButtonHero } from 'sdk/sections'
import { ActionButtonGetSDK } from 'sdk/action-buttons/ActionButtonGetSDK'

import heroImage from './assets/use-our-tech-hero-image.jpeg'
import heroVideo from './assets/use-our-tech-hero-video.webm'

export const Hero: React.FC = () => (
  <SimpleTitleButtonHero
    title="Use Our Tech"
    description="Unlock the GPU to power next generation audio and music production workflows"
    actionButton={(
      <ActionButtonGetSDK />
    )}
    imageLink={heroImage}
    videoLink={heroVideo}
  />
)
