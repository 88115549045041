import React, { FC, useState } from 'react'
import { useLoaderData } from 'react-router-dom'

import { Content } from 'sdk/components'
import { JoinOurNewsletter } from 'sdk/sections'
import { useAppSelector } from 'redux/store'

import { ProductRecord, userProfileSection } from 'core/interfaces'

import { ChangePasswordSection } from './sections/change-password/ChangePasswordSection'
import { ProfilePanel } from './profile-panel/ProfilePanel'
import { AccountDetailsSection } from './sections/account-details/AccountDetailsSection'
import { MyProductsSection } from './sections/my-products/MyProductsSection'
import { SubscribePanel } from './subscribe-panel/SubscribePanel'

import classes from './style.module.scss'

export const UserProfilePage: FC = () => {
  const { user } = useAppSelector((state) => state.user)

  const links = useAppSelector((state) => state.settings.links)

  const products = useLoaderData() as ProductRecord[]

  const [currentSection, setCurrentSection] = useState<userProfileSection>('myProducts')

  let selectedSection: React.ReactNode

  switch (currentSection) {
    case 'myProducts':
      selectedSection = <MyProductsSection products={products} />
      break
    case 'accountDetails':
      selectedSection = <AccountDetailsSection user={user!} />
      break
    case 'changePassword':
      selectedSection = <ChangePasswordSection />
      break
  }

  return (
    <>
      <Content
        element="section"
        mainWrapperClassName={classes.wrapper}
        className={classes.content}
      >
        <ProfilePanel
          user={user!}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          links={links!}
        />

        <SubscribePanel
          className={classes.subscribePanel}
        />

        { selectedSection }
      </Content>
      <JoinOurNewsletter />
    </>
  )
}
