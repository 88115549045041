import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps {
  children: ReactNode
  className?: string
}

export const Panel: FC<ComponentProps> = (props) => {
  const { children, className } = props

  return (
    <div className={classNames(classes.panel, className)}>
      { children }
    </div>
  )
}
