import * as React from 'react'

import { SimpleTitleButtonHero } from 'sdk/sections'
import { ActionButtonLearnMore } from 'sdk/action-buttons/ActionButtonLearnMore'

import { scrollToId } from 'core/utils'
import { SectionId } from 'core/interfaces'

import heroImage from './assets/home-hero-image.jpeg'
import heroVideo from './assets/home-hero-video.webm'

export const Hero: React.FC = () => (
  <SimpleTitleButtonHero
    title="Lightning Fast Audio, Real-Time Results"
    description="Unlock immense GPU power for the next generation of audio software, plugins, and platforms"
    actionButton={(
      <ActionButtonLearnMore
        onClick={() => scrollToId(SectionId.FlagshipProduct, 1.1)}
      />
    )}
    imageLink={heroImage}
    videoLink={heroVideo}
  />
)
