import * as React from 'react'
import { Link, useNavigate, useNavigation } from 'react-router-dom'

import { Content } from 'sdk/components'
import { MenuItemHeader } from 'sdk/menu-items/menu-item-header/MenuItemHeader'
import { AccountButton } from 'sdk/account-button/AccountButton'

import { UserRecord } from 'core/interfaces'
import { trackClick, disableScroll } from 'core/utils'

import { MobileMenu } from './mobile-menu/MobileMenu'
import { LinearProgress } from './linear-progress/LinearProgress'
import mobileMenuButton from './images/mobile-menu-button.svg'
import mobileMenuButtonCross from './images/mobile-menu-button-cross.svg'

import logo from 'images/logo.png'
import logoRetina from 'images/logo@2x.png'

import classes from './style.module.scss'

interface ComponentProps {
  user: UserRecord | undefined
}

export const Header: React.FC<ComponentProps> = (props) => {
  const { user } = props
  const { state } = useNavigation()

  const [menuOpened, setMenuOpened] = React.useState<boolean>(false)

  React.useEffect(() => {
    disableScroll(menuOpened)
  }, [menuOpened])

  const mobileMenuIcon = menuOpened ? mobileMenuButtonCross : mobileMenuButton
  const mobileMenuIconAlt = menuOpened ? 'mobile menu button cross' : 'mobile menu button'
  const navigate = useNavigate()

  return (
    <>
      <Content
        element="header"
        mainWrapperClassName={classes.wrapper}
        className={classes.content}
      >
        <button
          className={classes.mobileMenuButton}
          onClick={() => {
            trackClick('Mobile_menu')
            setMenuOpened(!menuOpened)
          }}
        >
          <img
            src={mobileMenuIcon}
            alt={mobileMenuIconAlt}
          />
        </button>

        <nav className={classes.links}>
          <Link to="/">
            <img
              src={logo}
              srcSet={`${logo} 1x, ${logoRetina} 2x`}
              alt="gpu audio logo"
            />
          </Link>

          <div className={classes.navLinks}>
            <MenuItemHeader link="/">
              Home
            </MenuItemHeader>
            <MenuItemHeader link="/use-our-tech">
              Use Our Tech
            </MenuItemHeader>
            <MenuItemHeader link="/newsfeed">
              News & Events
            </MenuItemHeader>
            <MenuItemHeader link="/support">
              Support
            </MenuItemHeader>
          </div>
        </nav>

        <AccountButton
          user={user}
          onClick={() => {
            menuOpened && setMenuOpened(false)
            navigate('/user-profile')
          }}
        />

      </Content>

      <MobileMenu menuOpened={menuOpened} closeMenu={() => setMenuOpened(false)} />
      <LinearProgress className={classes.progress} visible={state === 'loading'} />
    </>
  )
}
